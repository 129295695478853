<template>
  <schedules-container></schedules-container>
</template>

<script>
  import SchedulesContainer from '@/components/schedules/SchedulesContainer.vue'

  export default {
    name: 'Schedules',
    components: { SchedulesContainer }
  }
</script>
