<template>
  <div class="schedules-expansion q-mt-lg">
    <mi-expansion-item
      expand-icon-toggle
      expand-separator
      label="Department schedules"
      default-opened
    >
      <template #header>
        <slot name="header"></slot>
      </template>
      <slot name="body"></slot>
    </mi-expansion-item>
  </div>
</template>

<script>
  export default {
    name: 'SchedulesExpansion'
  }
</script>

<style lang="scss" scoped>
  ::v-deep(.mi-expansion-item__expand-icon) .q-expansion-item__toggle-icon {
    font-size: 16px;
    color: $mi-anthracite-800;
  }

  .schedules-expansion {
    background-color: $mi-white;
  }
</style>
