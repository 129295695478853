<template>
  <q-card :class="borderCss">
    <q-card-section v-if="!schedules?.length" class="flex justify-center full-width">
      <mi-btn
        flat
        icon-right="plus"
        icon-right-size="14px"
        size="md"
        @click="handleOpenModal(departmentName)"
      >
        {{ btnName }}
      </mi-btn>
    </q-card-section>
    <q-card-section v-else class="flex full-width q-pt-sm">
      <div class="full-width">
        <q-list bordered separator>
          <q-item
            v-for="(schedule) in schedules"
            :key="schedule.id"
            v-ripple
            clickable
            class="q-pa-md"
            @click="handleScheduleClick(schedule.id)"
          >
            <q-item-section>
              <div class="row items-center">
                <img
                  class="q-mr-md"
                  src="@/assets/images/folder-filled.svg"
                  alt="schedules-folder-icon"
                />
                <div>
                  <q-item-label>
                    {{ schedule.name }}
                  </q-item-label>
                  <q-item-label caption class="expansion-body__title">
                    Scheduled to {{
                      formatDate(schedule.nextScheduledDate, { includesTime: false })
                    }}
                  </q-item-label>
                </div>
                <div class="flex col-grow justify-end">
                  <mi-btn
                    color="transparent"
                  >
                    <q-icon class="text-dark" size="small">
                      <img src="@/assets/images/redirect_arrow.svg" alt="Navigate to Details" />
                    </q-icon>
                  </mi-btn>
                </div>
              </div>
            </q-item-section>
          </q-item>
        </q-list>
      </div>
    </q-card-section>
  </q-card>
</template>

<script>
  import { computed } from 'vue'
  import formatDate from '@/utils/formatDate'

  export default {
    name: 'SchedulesExpansionBody',
    props: {
      btnName: {
        type: String,
        required: false,
        default: ''
      },
      schedules: {
        type: Array,
        required: false,
        default: () => []
      },
      handleScheduleClick: {
        type: Function,
        required: false,
        default: () => {}
      },
      handleOpenModal: {
        type: Function,
        required: false,
        default: () => {}
      },
      departmentName: {
        type: String,
        required: false,
        default: ''
      }
    },
    setup(props) {
      const borderCss = computed(() => (!props.schedules?.length ? 'expansion-body__border' : ''))
      return { borderCss, formatDate }
    }
  }
</script>

<style lang="scss" scoped>
  .expansion-body {
    &__title {
      color: $mi-anthracite-800;
    }

    &__border {
      border-radius: .1rem;
      border: dashed .1rem $mi-anthracite-200;
    }
  }
</style>
