<template>
  <mi-list-item-section>
    <div>
      <span class="expansion-header__title text-body2 text-weight-bold text-family-condensed q-pr-sm">
        {{ departmentName }}
      </span>
      <span
        class="expansion-header__title--legend text-body2 text-weight-bold text-family-condensed"
      >
        {{ folderSize }} folder(s)
      </span>
    </div>
  </mi-list-item-section>
  <mi-list-item-section side>
    <mi-btn
      color="accent"
      icon-right="plus"
      icon-right-size="14px"
      size="md"
      @click="handleOpenModal(departmentName)"
    >
      {{ btnName }}
    </mi-btn>
  </mi-list-item-section>
</template>

<script>
  export default {
    name: 'SchedulesExpansionHeader',
    props: {
      btnName: {
        type: String,
        required: false,
        default: ''
      },
      departmentName: {
        type: String,
        required: false,
        default: ''
      },
      folderSize: {
        type: Number,
        required: false,
        default: 0
      },
      handleOpenModal: {
        type: Function,
        required: false,
        default: () => {}
      }
    }
  }
</script>

<style lang="scss" scoped>
  .expansion-header {
    &__title {
      color: $mi-anthracite-800;

      &--legend {
        color: $mi-anthracite-500;
      }
    }
  }
</style>
