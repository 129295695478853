<template>
  <div class="schedules-page">
    <mi-inner-loading class="configuration-info-card__inner-loading" :showing="isPageLoading">
      <mi-spinner size="70px"></mi-spinner>
      <h6 class="q-mt-lg q-mb-none"> Loading schedules. Please wait ... </h6>
    </mi-inner-loading>
    <div v-if="!isPageLoading">
      <div class="flex flex-column items-start">
        <h3 class="schedules-page__header-title q-mt-sm q-mb-sm"> Department schedules </h3>
      </div>
      <div v-if="!!departmentSchedules">
        <schedules-expansion
          v-for="(schedules, departmentKeyName) in departmentSchedules"
          :key="departmentKeyName"
        >
          <template #header>
            <schedules-expansion-header
              :btn-name="schedulesBtnName"
              :department-name="departmentKeyName"
              :folder-size="schedules.length"
              :handle-open-modal="handleOpenModal"
            >
            </schedules-expansion-header>
          </template>
          <template #body>
            <schedules-expansion-body
              :btn-name="schedulesBtnName"
              :schedules="schedules"
              :department-name="departmentKeyName"
              :handle-schedule-click="handleScheduleClick"
              :handle-open-modal="handleOpenModal"
            >
            </schedules-expansion-body>
          </template>
        </schedules-expansion>
        <div v-if="showScheduleModal">
          <schedules-form
            :department-name="selectedDepartment"
            :handle-close-modal="handleCloseModal"
          >
          </schedules-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { onMounted, ref } from 'vue'
  import { useRouter } from 'vue-router'
  import { NEW_SCHEDULE_BTN_TEXT as schedulesBtnName } from '@/constants'
  import { getSchedules } from '@/api/rest/scheduler'

  import SchedulesExpansion from '@/components/schedules/SchedulesExpansion.vue'
  import SchedulesExpansionHeader from '@/components/schedules/SchedulesExpansionHeader.vue'
  import SchedulesExpansionBody from '@/components/schedules/SchedulesExpansionBody.vue'
  import SchedulesForm from '@/components/schedules/drawer/SchedulesForm.vue'

  import { SCHEDULES_DETAILS_ROUTE } from '@/router/routeNames'

  export default {
    name: 'SchedulesContainer',
    components: {
      SchedulesForm,
      SchedulesExpansion,
      SchedulesExpansionHeader,
      SchedulesExpansionBody
    },
    setup() {
      const router = useRouter()
      const departmentSchedules = ref(null)
      const isPageLoading = ref(false)
      const showScheduleModal = ref(false)
      const selectedDepartment = ref('')

      const handleOpenModal = department => {
        showScheduleModal.value = true
        selectedDepartment.value = department
      }

      const fetchSchedules = async () => {
        isPageLoading.value = true
        const results = await getSchedules()
        departmentSchedules.value = results
        isPageLoading.value = false
      }

      const handleScheduleClick = id => {
        router.push({ name: SCHEDULES_DETAILS_ROUTE.name, params: { id } })
      }

      const handleCloseModal = ({ refetch = false, created = false, id = '' }) => {
        showScheduleModal.value = false
        if (!created) {
          refetch && fetchSchedules()
        }
        else {
          handleScheduleClick(id)
        }
      }

      onMounted(fetchSchedules)

      return {
        showScheduleModal,
        handleOpenModal,
        handleCloseModal,
        schedulesBtnName,
        departmentSchedules,
        selectedDepartment,
        isPageLoading,
        handleScheduleClick
      }
    }
  }
</script>

<style lang="scss" scoped>
  .schedules-page {
    max-width: 100vw;
    min-height: 100%;
    padding: 24px;
    background: $mi-anthracite-50;

    &__header-title {
      line-height: 36px;
    }
  }
</style>
